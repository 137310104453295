import PropTypes from "prop-types";
import React from "react"
import * as util from "./util"
import { FILTER_IN_NEXT_MONTH } from "../components/service/index"
import styled from "styled-components"
import dayjs from "dayjs"
import logo from "../images/wdp.png"
import { graphql } from 'gatsby'

const PrintableEvent = styled.div`
  padding: 1.5rem;
  font-size: 1.5rem;
  font-family: "Lato";
  color: #000;
  border: 0;
  border-left-width: 3px;
  border-color: #000;
  border-style: solid;
  margin: 0.5rem;
`

const PrintableSpendPartner = styled.div``

const PrintableEventTitle = styled.h1`
  font-family: "Lato";
  font-weight: 700;
`

const PrintableEventsList = styled.ul`
  display: flex;
`

const PrintableHeader = styled.h1`
  font-family: "Lato";
  font-weight: 700;
  font-size: 6rem;
  color: #000;
`

const PrintableSubHeader = styled.h2`
  font-family: "Lato";
  font-weight: 300;
  font-size: 3rem;
  color: #000;
`

const PrintableEventsPage = styled.div`
  border: 0;
  border-top-width: 5px;
  padding-top: 5px;
  border-color: #000;
  border-style: solid;
`

const SpecialConditionsList = ({ specialConditions }) => {
  if (!specialConditions) return null

  return specialConditions.map((condition, idx) => <li key={idx}>{condition}</li>)
}

SpecialConditionsList.propTypes = {
  specialConditions: PropTypes.array.isRequired
}

const PrintableEventContainer = props => {
  return (
    <PrintableEvent className="col-12">
      <PrintableEventTitle>
        {props.description} ({props.pointsRequired} POINTS)
      </PrintableEventTitle>
      <h2>{dayjs(props.when).format("DD / MM / YYYY")}</h2>
      <ul>
        <SpecialConditionsList specialConditions={props.specialConditions} />
      </ul>
    </PrintableEvent>
  )
}

PrintableEventContainer.propTypes = {
  description: PropTypes.any.isRequired,
  pointsRequired: PropTypes.bool.isRequired,
  specialConditions: PropTypes.array.isRequired,
  when: PropTypes.any
}

const PrintableComponent = props => {
  const data = props.data

  const events = data.allContentfulCapitalCardEvent.edges
    .filter(util.eventFilters(FILTER_IN_NEXT_MONTH))
    .map(event => event.node)

  return (
    <PrintableEventsPage className="container">
      <div className="row">
        <div className="col-6">
          <img src={logo} className="img-fluid" />
        </div>
        <div className="col-6">
          <PrintableHeader>WHAT'S ON?</PrintableHeader>
          <PrintableSubHeader>
            CAPITAL CARD EVENTS IN THE NEXT MONTH
          </PrintableSubHeader>
        </div>
      </div>
      <PrintableEventsList className="row">
        {events.map((event) => (
          <PrintableEventContainer key={event.name} {...event} />
        ))}
      </PrintableEventsList>
    </PrintableEventsPage>
  )
}

PrintableComponent.propTypes = {
  data: PropTypes.shape({
    allContentfulCapitalCardEvent: PropTypes.shape({
      edges: PropTypes.shape({
        filter: PropTypes.func
      })
    })
  })
}

export default PrintableComponent

export const printableQuery = graphql`
  query PrintableServiceById($id: String!, $today: Date!) {
    allContentfulCapitalCardEvent(
      filter: {
        service: { elemMatch: { id: { eq: $id } } }
        when: { gt: $today }
      }
    ) {
      edges {
        node {
          description
          when
          pointsRequired
          arePointsRequired
          specialConditions
          spendPartner {
            name
            description {
              raw
            }
            logo {
              resize(width: 128, height: 128) {
                src
              }
            }
          }
        }
      }
    }

    allContentfulCapitalCardSpendPartner(
      filter: { service: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          name
          description {
            raw
          }
          categories {
            name
          }
          logo {
            resize(width: 128, height: 128) {
              src
            }
          }
          service {
            id
            name
          }
        }
      }
    }

    contentfulService(id: { eq: $id }) {
      id
      name
    }
  }
`
